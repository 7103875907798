/* *:focus {
  box-shadow: none !important;
} */

.MuiDataGrid-root {
  border: none !important;
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-cell {
  padding: 0 !important;
  border-bottom: 0 !important;
}

.MuiDataGrid-row:hover {
  background: transparent !important;
}

.MuiDataGrid-row {
  margin-bottom: 16px !important;
}
